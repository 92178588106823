<style lang="scss">
@import "~@/assets/css/var";

$back: #F2F5FE;
$blue: #6684EC;

.general-template {
    padding-top: 30px;
    width: 1080px;
    // margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 100px);
    overflow: hidden;
    box-sizing: border-box;

    &__condition {
        // padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .desc {
            font-size: 16px;
            color: $main;
        }

        .condition_flex {
            display: flex;
            align-items: center;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;

            .el-select {
                min-width: 150px;
            }

            .title {
                color: #3D3D3D;
                font-size: 16px;
                font-weight: bold;
                margin-right: 10px;
                display: flex;
                align-items: center;
            }

            .title::before {
                content: '';
                display: inline-block;
                width: 3px;
                height: 18px;
                margin-right: 5px;
                background-color: #456DF8;
            }

            .text {
                margin-left: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__my {
        float: right;
        color: $main;
    }

    &__txt {
        position: relative;
        box-sizing: border-box;
        // width: 1080px;
        width: 100%;
        // min-height: 400px;
        margin: 20px 0;
        height: calc(100% - 70px);

        /*padding: 8px 50px;*/
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;


        border: 1px solid #eee;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

        font-size: 10.5pt;
        line-height: 1.5;

        * {
            box-sizing: border-box;
        }
        iframe{
            height: 100%;
        }

        p {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }

        .my-canvas {
            margin: 0 auto;
            width: 1200px;
            height: 900px;
            opacity: 0;
        }

        img {
            width: unset;
        }

        .title {
            text-align: center;
            font-size: 17.5pt;
            font-weight: bold;
        }

        .red {
            color: #F11C1C;
        }

        .center {
            text-align: center;
        }

        h1 {
            font-size: 16pt;
            font-weight: bold;
            line-height: 1.72;
        }

        h2 {
            font-size: 14pt;
            font-weight: bold;
        }

        h3 {
            font-size: 12pt;
            font-weight: bold;
        }

        table {
            border-width: 1px;
            border-color: #666666;
            border-collapse: collapse;
            width: 100%;
            font-size: 11pt;

        }

        table tr {
            page-break-inside: avoid ! important;
        }

        table .no-data {
            padding: 50px;
            text-align: center;
        }

        table th {
            border-width: 1px;
            padding: 8px;
            border-style: solid;
            border-color: #666666;
            background-color: #dedede;
            vertical-align: middle;
        }

        table td {
            border-width: 1px;
            padding: 8px;
            border-style: solid;
            border-color: #666666;
            vertical-align: middle;
        }

        table td .bar {
            display: inline-block;
            height: 10px;
            width: 50px;
            border-radius: 10px;
            margin-right: 5px;
            vertical-align: middle;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    &__img {
        display: block;
        overflow: hidden;
        height: 400px;
    }

    .loading-panel {
        height: 400px;
        overflow: hidden;
    }

    &__empty {
        font-size: 28px;
        // padding: 180px 0;
        // line-height: 40px;
        color: #999;
        text-align: center;
        background: unset;
        background-image: url('../../../assets/img/general_bg.png');
        background-repeat: no-repeat;
        /* 防止背景图像平铺 */
        background-position: center center;
        /* 将背景图像居中放置 */
        background-size: 15%;
        /* 缩放背景图像 */
    }

    .word-max {
        position: absolute;
        right: 50px;
        top: 32px;
        color: #fff;
    }

    .word-min {
        position: fixed;
        z-index: 1001;
        top: 33px;
        right: 55px;
        color: #fff;
        font-size: 12px;
    }

    .max-frame {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
    }

    .el-loading-spinner {
        width: 100px;
        height: 100px;
        background-image: url('../../../assets/img/loading.gif') !important;
        /* 替换为你的自定义图标路径 */
        background-repeat: round;
        animation: none;
        /* 可能需要禁用原动画 */
        right: 50%;
    }

    .el-loading-spinner .circular {
        display: none !important;
    }

    .el-loading-spinner .el-loading-text {
        margin-top: 110px;
    }
}

.general-report-btn {
    padding-top: 50px;
    text-align: center;
}



.ts-menu {
    background: $back;
    height: 100%;

    i {
        color: $blue;
    }

    .el-submenu__title {
        height: 46px;
        line-height: 46px;
        font-size: 12px;
        font-weight: bold;
    }

    .el-menu {
        background: transparent;

        li {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            color: #575757;

            &:hover,
            &:focus,
            &.is-active {
                background: $blue;
                color: #fff;
            }
        }
    }

    // .is-opened,
    // .is-active {
    //     .el-submenu__title {
    //         background: #96acf9;
    //         color: #fff;
    //     }
    //     i{
    //         color: #fff;
    //     }
    // }
}
</style>

<template>
    <div class="general-template" v-if="!$help.token.get()" style="display: unset;">
        <ts-error></ts-error>
    </div>
    <div v-else class="general-template" v-loading="loading" element-loading-text="文件解析中">
        <div class="general-template__tab" style="width: 230px;padding-bottom: 20px;">
            <!-- <el-radio-group :disabled="!!readTxt" v-model="index" size="normal" @change="typeChange">
                <el-radio-button v-for="(item, i) in list" :key="item.reportType" :label="i">{{ item.reportTypeName
                    }}</el-radio-button>
            </el-radio-group> -->


            <el-menu :default-active="editableTabsValue" :default-openeds="defaultOpeneds"  class="ts-menu el-menu-vertical-demo" @select="getMenu"
                :unique-opened="false"  >
                <el-submenu :index="`${parent.id}_ ${parent.key}`" v-for="(parent, y) in menuList"
                    :key="`${parent.id}_ ${parent.key}`">
                    <template slot="title">
                        <i class="iconfont" :class="parent.icon" style="margin-right: 5px;"></i>
                        <span>{{ parent.value }}</span>
                    </template>
                    <el-menu-item v-for="(child, k) in parent.children"
                        :key="`${parent.id}_${child.reportParamsType}${k}`"
                        :index="`${y}-${child.reportType}_${child.reportTypeName}`">
                        {{ child.reportTypeName }}</el-menu-item>
                </el-submenu>
            </el-menu>
        </div>


        <div style="width:calc(100% - 250px);padding-right: 20px;box-sizing: border-box;">
            <!--下拉块-->
            <div class="general-template__condition">
                <div class="condition_flex">
                    <div class="desc" v-if="readTxt">{{ readTxt }}</div>
                    <template v-else>
                        <div class="title">{{ topTitle }}</div>
                        <pick-drug-corp v-if="model.reportParamsType === 'COM_COMP_PARAMS_TYPE'"
                            @change="optionChange"></pick-drug-corp>
                        <pick-drug v-else-if="model.reportParamsType === 'COM_PARAMS_TYPE'"
                            @change="optionChange"></pick-drug>
                        <pick-corp v-else-if="model.reportParamsType === 'COMP_PARAMS_TYPE'"
                            @change="optionChange"></pick-corp>
                        <el-tooltip v-if="tooltipText" class="text" effect="light" :content="tooltipText"
                            placement="top">
                            <div class="text"
                                :style="{ marginLeft: model.reportParamsType === 'COM_COMP_PARAMS_TYPE' ? '40px' : '10px' }">
                                <i class="el-icon-info" style="color: #F7AE00;font-size: 16px;"></i>
                                {{ tooltipText }}
                            </div>
                        </el-tooltip>
                    </template>
                </div>
                <el-button type="primary" icon="el-icon-document" @click="goList">我的报告</el-button>
            </div>

            <div v-if="!imgParam" class="general-template__txt general-template__empty"></div>

            <div v-else style="height: calc(100% - 70px);">
                <template v-if="imgLoaded">
                    <div class="general-template__txt" style="height: 100%;">
                        <div id="corp-word"></div>
                        <div class="word-min clickable" v-if="max" @click="minFrame"><i class="el-icon-full-screen"></i>
                        </div>
                        <div class="word-max clickable" v-else @click="maxFrame"><i class="el-icon-full-screen"></i>
                        </div>
                    </div>
                </template>
                <div v-else>
                    <!--canvas集合-->
                    <div class="general-template__txt general-template__img" style="height: 100%;"
                        v-if="model.reportParamsType && model.imgList && model.imgList.length > 0">
                        <div v-for="item in model.imgList" :key="item.key" :id="item.key" class="my-canvas"></div>
                    </div>
                </div>
            </div>

        </div>


        <div style="display: none;opacity: 0;position: fixed;left:0;right:0;z-index: -100">
            <div id="corp-word1"></div>
        </div>
    </div>
</template>

<script>
import PickDrugCorp from './PickDrugCorp'
import PickCorp from './PickCorp'
import PickDrug from './PickDrug'
import canvasHelp from './help'
import emptyImg from './emptyImg'

const canvasType = {
    line: 'line',
    mulLine: 'mulLine',
    mulLineRatio: 'mulLineRatio',
    mulBar: 'mulBar',
    pie: 'pie',
    specialMul: 'specialMul',  // 药品分析报告的柱状折线图
    barLineQuarter: 'barLineQuarter',
    barLineYear: 'barLineYear',
}
export default {
    components: { PickDrugCorp, PickCorp, PickDrug },
    data() {
        return {
            visitId: 0,
            pageId: '',
            pageName: '',


            readTxt: '',
            index: 0,
            model: {},
            list: [],

            // 加载数据
            loading: false,
            yearMap: {},
            quarterMap: {},
            yearList: 0,
            imgParam: false,

            max: false,
            imgLoaded: false,
            docParam: {},

            menuList: [],
            editableTabsValue: '',
            defaultOpeneds:[],
            topTitle: '',
            tooltipText: '',
            tooltipList: [
                { type: '1', text: '针对某个药品进行分析，含所有生产企业' },
                { type: '2', text: '针对某企业所有产品全面分析，评估企业综合价值和潜力' },
                { type: '3', text: '针对某企业、某个药品进行定向分析' },
                { type: '5', text: '针对某企业、某产品推广代理的可行性、安全性分析' },
            ]
        }
    },
    created() {
        if (!this.$help.token.get()) {
            return false
        }

        // 加载年份数据
        this.$api.get('setting/year/recent5YearList')
            .then(res => {
                const years = res.data
                for (let key in years) {
                    const item = years[key]

                    // 满季度才会显示年份
                    if (item.quarter.length === 4) {
                        this.$set(this.yearMap, key - 1, item.year)
                    }

                    // 处理季度
                    item.quarter.forEach((quarter, index) => {
                        this.$set(this.quarterMap, `${key - 1}Q${index + 1}`, `${item.year}Q${index + 1}`)
                    })
                }
            })

        if (this.$route.query.id) {
            this.loading = true
            this.$api.get('sys/sysUserReport/findSysUserReportById', { id: this.$route.query.id })
                .then(res => {
                    this.imgParam = true
                    this.imgLoaded = true
                    this.readTxt = res.data.title
                    this.docParam = {
                        title: res.data.title,
                        url: res.data.reportFile,
                        key: res.data.fileKey
                    }
                    this.model.reportType = res.data.reportType
                    this.loading = false
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.loadWord({})
                        }, 10)
                    })

                })
        } else {
            this.$api.get('report/sysUserReport/reportType')
                .then(res => {
                    const arr = [
                        { id: 1, icon: 'icon-jibenxinxi', key: 'COM_PARAMS_TYPE', value: '药品分析报告', children: [] },
                        { id: 2, icon: 'icon-yaopinxinwen', key: 'COMP_PARAMS_TYPE', value: '企业分析报告', children: [] },
                        { id: 3, icon: 'icon-linchuanglujing1', key: 'COM_COMP_PARAMS_TYPE', value: '企业药品分析报告', children: [] },
                    ]
                    this.list = res.data.map(item => {
                        switch (item.reportParamsType) {
                            case 'COM_COMP_PARAMS_TYPE':
                                // 企业药品分析报告
                                item.imgList = [
                                    {
                                        key: 'keyYearSale',
                                        type: canvasType.line,
                                        field: 'compName',
                                        passField: 'comSaleTendPic',
                                    },
                                    {
                                        key: 'sumYearAmountAndRateByCompCodePic',
                                        type: canvasType.line,
                                        field: 'compName',
                                        passField: 'sumYearAmountAndRateByCompCodePic',
                                    },
                                    {
                                        key: 'compSaleList',
                                        type: canvasType.mulLine,
                                        field: 'compName',
                                        passField: 'comSaleTendGroupCompPic',
                                    },
                                    {
                                        key: 'compSaleRatioList',
                                        type: canvasType.pie,
                                        field: 'compName',
                                        passField: 'compSaleAmountRatioPic'
                                    },
                                    {
                                        key: 'yearComSaleRatio',
                                        type: canvasType.pie,
                                        field: 'comName',
                                        passField: 'saleAmountRatioPic'
                                    },
                                    {
                                        key: 'yearComSale',
                                        type: canvasType.mulLine,
                                        field: 'comName',
                                        passField: 'saleTendGroupDosePic'
                                    },
                                ]
                                arr[2].children.push(item)
                                break
                            case 'COM_PARAMS_TYPE':
                                // 药品分析报告
                                item.imgList = [
                                    {
                                        key: 'compSaleYear',
                                        type: canvasType.pie,
                                        field: 'compName',
                                        valueField: 'ratio',
                                        passField: 'comCodeSaleGrandTotalByCompPic'
                                    },
                                    {
                                        key: 'compSaleLastYear',
                                        type: canvasType.pie,
                                        field: 'compName',
                                        valueField: 'ratio',
                                        passField: 'comCodeSaleLastTotalByCompPic'
                                    },
                                    {
                                        key: 'treatSaleAndRate',
                                        type: canvasType.specialMul,
                                        field: '',
                                        passField: 'comCodeSaleTotalAndRatePic'
                                    },
                                    {
                                        key: 'treatSale',
                                        type: canvasType.line,
                                        field: 'showTreat',
                                        passField: 'comCodeSaleTotalByTreatPic'
                                    },
                                    {
                                        key: 'keySaleQuarter',
                                        type: canvasType.barLineQuarter,
                                        barField: 'amount',
                                        lineField: 'amountRate',
                                        passField: 'keyCodeQuarterSalePic'
                                    },
                                    {
                                        key: 'keySaleDose',
                                        type: canvasType.mulBar,
                                        field: 'comName',
                                        passField: 'keyCodeSaleTendByDosePic'
                                    },
                                    {
                                        key: 'keySaleSpec',
                                        type: canvasType.mulBar,
                                        field: 'comName',
                                        assist: 'unifySpec',
                                        passField: 'keyCodeSaleTendBySpecPic'
                                    },
                                    {
                                        key: 'keySaleYear',
                                        type: canvasType.barLineYear,
                                        barField: 'amount',
                                        lineField: 'amountRate',
                                        passField: 'keyCodeYearSalePic'
                                    },
                                ]
                                arr[0].children.push(item)
                                break
                            case 'COMP_PARAMS_TYPE':
                                // 企业分析报告
                                item.imgList = [
                                    {
                                        key: 'compSaleTrend',
                                        type: canvasType.line,
                                        field: 'compName',
                                        passField: 'compSalesTrendPic'
                                    },
                                    {
                                        key: 'channelMapList',
                                        type: canvasType.mulLine,
                                        field: 'drugsChannel',
                                        passField: 'drugChannelAmountPic'
                                    },
                                    // {
                                    //     key: 'drugChannelRatio',
                                    //     type: canvasType.mulLineRatio,
                                    //     field: 'drugsChannel',
                                    //     passField: 'drugChannelRatioPic'
                                    // },
                                ]

                                arr[1].children.push(item)
                                break
                        }
                        return item
                    })
                    this.menuList = arr
                    this.defaultOpeneds = this.menuList.map(parent => `${parent.id}_ ${parent.key}`)
                    this.editableTabsValue = 0 + '-' + this.menuList[0].children[0].reportType + '_' + this.menuList[0].children[0].reportTypeName
                    this.topTitle = this.menuList[0].children[0].reportTypeName
                    this.setTooltipText(this.menuList[0].children[0].reportType)
                    this.typeChange(this.menuList[0].children[0])
                })
        }

    },
    mounted() {
        // 加载空的word，用于缓存
        new window.DocsAPI.DocEditor("corp-word1", {
            "width": "100px",
            "height": '100px',
            "type": "desktop",
            "token": "",
            "document": {
                "title": '',
                "url": 'https://taoshu-data.jundaodsj.com/空白模板.docx',
                "fileType": "docx",
                key: `a1fe26450aeeac6afc129f4493e2df63-${new Date().getTime()}`,
            },
        });
    },
    methods: {
        setTooltipText(val) {
            this.tooltipText = ''
            for (let index = 0; index < this.tooltipList.length; index++) {
                if (this.tooltipList[index].type === val) {
                    this.tooltipText = this.tooltipList[index].text
                    break
                }

            }
        },
        getMenu(val) {
            const param = val.split('-')
            const index = param[0]   // 父级索引
            const _index = param[1].split('_')[0]  // 子级索引
            this.topTitle = param[1].split('_')[1]
            this.setTooltipText(_index)
            for (let i = 0; i < this.menuList[index].children.length; i++) {
                if (this.menuList[index].children[i].reportType === _index) {
                    this.typeChange(this.menuList[index].children[i])
                    break;
                }

            }
        },
        goList() {
            const { href } = this.$router.resolve({
                path: '/report/self',
            })
            window.open(href, "_blank")
        },
        typeChange(obj) {
            this.imgParam = false
            this.model = {}
            this.$help.store.remove('newParam')
            this.$nextTick(() => {
                // this.model = this.list[this.index]
                this.model = obj

                this.visitId = new Date().getTime()
                this.pageId = this.model.reportType
                this.pageName = '药品搜索-' + this.model.reportTypeName
                this.$help.socket.send(this)
            })
        },
        getCanvasItem(obj = {}, field, yearValFmt, asistfield, prefix = 'amount') {
            const param = {
                name: obj[field]
            }
            if (asistfield) {
                param.name += ` ${obj[asistfield]}`
            }
            this.yearList.forEach(item => {
                param[item.value] = yearValFmt ? yearValFmt(obj[prefix + item.key]) : obj[prefix + item.key]
            })
            return param
        },
        optionChange(param) {
            // 开始加载图片
            this.loading = true
            this.imgLoaded = false
            this.imgParam = false
            param.reportType = this.model.reportType
            this.$api.post('report/sysUserReport/picParam', param)
                .then(res => {
                    this.imgParam = res.data
                    // 处理年份基本参数
                    // this.$variable.years.forEach(item => {
                    //     this.$set(this.yearMap, item.key - 1, item.value)
                    //
                    // })
                    this.yearList = Object.keys(this.yearMap).map(item => {
                        return {
                            key: item,
                            value: this.yearMap[item],
                        }
                    })

                    // 处理季度
                    // this.yearList.forEach(item => {
                    //     for (let i = 1; i < 5; i += 1) {
                    //         this.$set(this.quarterMap, `${item.key}Q${i}`, `${item.value}Q${i}`)
                    //     }
                    // })
                    // canvas对应div添加完毕后，开始画echarts
                    this.$nextTick(() => {
                        this.model.imgList?.forEach(item => {
                            switch (item.type) {
                                case canvasType.line: {
                                    let data = this.imgParam[item.key]
                                    if (data) {
                                        if (Array.isArray(data)) {
                                            data = data[0]
                                        }
                                    } else {
                                        data = {}
                                    }
                                    const val = this.getCanvasItem(data)
                                    delete val.name
                                    canvasHelp.renderLine(item.key, Object.keys(val).map(p => ({
                                        year: p,
                                        value: val[p]
                                    })))
                                    break
                                }
                                case canvasType.mulLine:
                                    canvasHelp.renderMulLine(item.key,
                                        this.imgParam[item.key] ? this.imgParam[item.key].map(child => this.getCanvasItem(child, item.field)) : [],
                                        this.yearList.map(child => child.value)
                                    )
                                    break
                                case canvasType.mulBar: {
                                    let data = this.imgParam[item.key]
                                    if (data && data.length) {
                                        canvasHelp.renderMulBar(item.key,
                                            data.map(child => this.getCanvasItem(child, item.field, undefined, item.assist)),
                                            this.yearList.map(child => child.value)
                                        )
                                    }
                                    break
                                }
                                case canvasType.mulLineRatio:
                                    canvasHelp.renderMulLineRatio(item.key,
                                        this.imgParam[item.key].map(child => this.getCanvasItem(child, item.field, undefined, undefined, 'ratio')),
                                        this.yearList.map(child => child.value)
                                    )
                                    break
                                case canvasType.pie:
                                    canvasHelp.renderPie(item.key, this.imgParam[item.key] ? this.imgParam[item.key].map(p => ({
                                        name: p[item.field],
                                        value: p[item.valueField || 'amount'] || 0,
                                    })) : [])
                                    break
                                case canvasType.specialMul: {
                                    if (this.imgParam[item.key] && this.imgParam[item.key].treatTotal) {
                                        const data1 = this.getCanvasItem(this.imgParam[item.key].treatTotal, 'showTreat')
                                        const data2 = this.getCanvasItem(this.imgParam[item.key].yearComAmount, 'comName')
                                        const data3 = this.getCanvasItem(this.imgParam[item.key].proportion,
                                            'name',
                                            item => this.$help.accDiv(parseFloat(item), 100),
                                            undefined,
                                            'ratio'
                                        )
                                        canvasHelp.renderBarLine(item.key,
                                            data1 && data2 ? [data1, data2] : [],
                                            data3 ? [data3] : [],
                                            this.yearList.map(child => child.value)
                                        )
                                    }
                                    break
                                }
                                case canvasType.barLineQuarter: {
                                    if (this.imgParam && this.imgParam[item.key] && this.imgParam[item.key][item.barField] && this.imgParam[item.key][item.lineField]) {
                                        const amountMap = this.imgParam[item.key][item.barField]
                                        const rateMap = this.imgParam[item.key][item.lineField]
                                        amountMap.name = '销售额'
                                        rateMap.name = '增长率'
                                        const quarter = []
                                        for (let key in this.quarterMap) {
                                            if (amountMap[key] === undefined) {
                                                break
                                            }
                                            quarter.push({
                                                key: key,
                                                value: this.quarterMap[key]
                                            })
                                        }
                                        canvasHelp.renderMinBarLine(item.key, amountMap, rateMap, quarter)
                                    }
                                    break
                                }
                                case canvasType.barLineYear: {
                                    if (this.imgParam && this.imgParam[item.key] && this.imgParam[item.key][item.barField]) {
                                        const amountMap = this.getCanvasItem(this.imgParam[item.key][item.barField])
                                        const rateMap = this.getCanvasItem(
                                            this.imgParam[item.key][item.lineField],
                                            undefined,
                                            item => this.$help.accDiv(parseFloat(item), 100),
                                            undefined,
                                            'rate'
                                        )
                                        amountMap.name = '销售额'
                                        rateMap.name = '增长率'
                                        canvasHelp.renderBarLine(item.key, [amountMap], [rateMap], this.yearList.map(child => child.value))
                                    }
                                    break
                                }
                            }
                        })
                        param.param = {}
                        this.model.imgList?.forEach(item => {
                            param.param[item.passField] = this.getBase64ByEcharts(item.key)
                        })
                        this.$api.post('report/sysUserReport/createWord', param, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                            .then(res => {
                                this.imgLoaded = true
                                this.docParam = res.data || {}
                                this.$help.store.set('newParam', JSON.stringify(this.docParam))
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        this.loadWord(param)
                                    }, 10)
                                })
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    })
                })
        },
        getBase64ByEcharts(id) {
            try {
                return window.echarts.init(document.getElementById(id)).getDataURL().replace('data:image/png;base64,', '')
            } catch (e) {
                return emptyImg
            }
        },
        loadWord(param) {
            const fullHeight = document.querySelector('.taoshu-body').clientHeight
            const tabHeight = document.querySelector('.general-template__tab').clientHeight
            const conditionHeight = document.querySelector('.general-template__condition').clientHeight
            new window.DocsAPI.DocEditor("corp-word", {
                "width": "100%",
                "height": `${fullHeight - tabHeight - conditionHeight - 75}px`,
                "type": "desktop",
                "token": "",
                "document": {
                    "title": this.docParam.title,
                    "url": this.docParam.url,
                    "fileType": "docx",
                    "key": `${this.docParam.key}-${new Date().getTime()}`,
                    "info": {
                        "owner": "Me",
                        "favorite": null
                    },
                    "permissions": {
                        "comment": false,
                        "copy": true,
                        "download": true,
                        "edit": true,
                        "print": false,
                        "fillForms": true,
                        "modifyFilter": true,
                        "modifyContentControl": true,
                        "review": true,
                        "reviewGroups": null,
                        "commentGroups": {},
                        "userInfoGroups": null
                    }
                },
                "editorConfig": {
                    spellcheck: false,
                    "actionLink": null,
                    "mode": "edit",
                    "lang": "zh",
                    "callbackUrl": `${window.location.origin}/taoshu-data/pc/report/sysUserReport/saveReport?reportType=${this.model.reportType}&compCode=${param.compCode || ''}&comCode=${param.comCode || ''}&token=${this.$help.token.get().replace('Bearer ', '')}`,
                    "user": {
                        "id": this.$store.state.user.id,
                        "name": this.$store.state.user.userName
                    },

                    "customization": {
                        "about": false,
                        "comments": false,
                        "feedback": false,
                        "forcesave": true,
                        "help": false,
                        "hideRightMenu": true,
                        "chat": false,
                        "spellcheck": false,
                        // "goback": {
                        //     "url": "http://localhost/example/"
                        // },
                        "submitForm": false
                    },
                    "fileChoiceUrl": "",
                    "plugins": {
                        "pluginsData": [
                            '/officePlugin/test/config.json'
                        ]
                    }
                }
            });
        },
        maxFrame() {
            this.max = true
            document.querySelector('iframe').className = 'max-frame'
        },
        minFrame() {
            this.max = false
            document.querySelector('iframe').className = ''
        },
    },
}
</script>